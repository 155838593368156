/* src/components/PokeCircleLoading.css */
.poke-circle-loading {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pokeball {
    width: 32px;
    height: 32px;
    animation: rotate 1s steps(8) infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  