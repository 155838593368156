.bet-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;


    .bet-title {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
    }

    .bet-form{
        width: 25%;
    }

    .bet-form-section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0;
        width: 100%;

        .bet-question-input{
            width: 65%;
        }
    }

    .bet-form-section-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0;

        .bet-new-answer{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .text{
                text-align: center;
                width: 15%;
            }
            .text-input{
                width: 40%;
            }

            .quote{
                text-align: center;
                width: 15%;
            }

            .quote-input{
                width: 20%;
            }
        }
    }

    .bet-answers {
        color: #fff;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .bet-answer-label {
                display: flex;
                margin-left: 24px;
                flex-direction: row;
                justify-content: space-around;
            }

            .answer-text{
                margin: 0;
                margin-right: 24px;
            }

            .answer-odds{
                margin: 0;
                color: red;
            }
        }
    }

    #close-bet-btn{
        margin-top: 12px;
    }

    .closing-error{
        color: red;
    }

    .sending-error{
        color: red;
    }
}