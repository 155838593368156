.shop-overlay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    height: calc(100% - 128px);
    width: 100%;

    .popup{
        background-color: #fff;
        border-radius: 24px;
        padding: 24px 48px;

        .question{
            margin-bottom: 0;
        }

        .transaction-id{
            margin: 0;
        }

        .buttons{
            margin-top: 24px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .buttons > a{
            cursor: pointer;
            background-color: var(--primary);
            border-radius: 24px;
            color: #fff;
            padding: 12px 24px;
        }

        .buttons > .cancel:hover{
            color: red;
        }

        .buttons > .complete:hover{
            color: lime;
        }
    }

}
