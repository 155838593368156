.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 10;
    background-color: transparent;
    //background-color: var(--primary);
}

.header-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    padding: 12px 24px;
    justify-content: space-between;
    box-sizing: border-box;
    //box-shadow: 0 0 72px 0 var(--secondary);
    height: 128px;
    position: fixed;
}

.scrolled {
    background-color: var(--bg-color);
    transition: background-color 0.3s ease-in;
    border-bottom: 1px solid var(--logo-color);
}

.menu-wrapper-btn {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.menu-btn {
    color: var(--primary);
    background-color: var(--logo-color);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-btn:hover {
    font-weight: bold;
    cursor: pointer;
    border: 1px solid var(--primary);
}



.logo>img {
    width: 512px;
}

.user-balance {
    color: var(--primary);
    margin: 0;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        margin-left: 8px;
    }
}

.logo-jestoni {
    width: 24px;
}

.profile-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 2px solid var(--secondary);
    cursor: pointer;
}

.profile-btn:hover {
    opacity: 0.8;
    filter: saturate(50%);
}

.profile-btn-sub {
    border: 2px solid goldenrod;
}

.twitch-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--twitch-primary);
    padding: 6px 12px;
    text-decoration: none;
    border: 1px solid rgba($color: #000000, $alpha: 0);
}

.twitch-login>p {
    color: #fff;
    font-size: 18px;
    margin: 0;
    margin-left: 12px;
}

.twitch-login:hover {
    border: 1px solid var(--twitch-secondary);
}

.twitch-mobile-login {
    width: 100%;
    background-color: var(--twitch-primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        color: #fff;
    }
}

.profile-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
}

.profile-wrapper-data{
    background-color: var(--logo-color);
    border-radius: 12px;
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-mobile-menu-wrapper>.nav-mobile-btn {
    margin-left: 8px;
    text-decoration: none;
}

.nav-mobile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    top: 128px;
    right: -500px;
    opacity: 0;
    transition: right 1s ease-in-out;
    //transition: opacity 0.5s ease-in-out;
}

.nav-mobile {
    padding: 0;
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.nav-mobile>a {
    text-decoration: none;
    color: #fff;
    padding: 24px 36px;
    background-color: var(--secondary);
    box-sizing: border-box;
    width: 100%;
}

.nav-mobile-buttons {
    list-style: none;
    margin: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 24px 48px;
    box-sizing: border-box;
}

.nav-mobile-buttons>a {
    color: var(--primary);
    text-decoration: none;
    cursor: pointer;
}

.nav-mobile-open {
    right: 0px;
    opacity: 1;
}

.mobile-user-balance {
    background-color: #fff;
    color: var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 36px;
}

.nav-mobile>a.nav-active {
    background-color: var(--primary);
}

a.nav-active-purchase {
    color: red;
}


.amount {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        margin-left: 8px;
    }
}

@media screen and (max-width: 960px) {
    .header-wrapper{
        background-color: var(--bg-color);
        border-bottom: 1px solid var(--logo-color);
        padding: 8px 12px;
    }

    .header {
        position: fixed;
        //background-color: var(--primary);
        width: 100%;

        .menu-wrapper-btn{
            width: 15%;
        }

       

        .logo > img{
            width: 256px;
        }

        .profile-wrapper{
            width: 15%;

            .profile-wrapper-data{
                background-color: transparent;
                padding: 0;
            }

            .profile-btn{
                width: 36px;
                height: 36px;
            }
        }

        #main-menu {
            top: 96px;
        }
    }

    .twitch-login{
        border-radius: 24px;
    }

    .logo>img {
        width: 192px;
    }
}