.coming-soon-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../public/img/background1.png");
    background-repeat: no-repeat;
}

.coming-soon-img {
    background-image: url("../../public/img/coming_soon_text.png");
    width: 512px;
    height: 256px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(-14deg);

    .social-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transform: rotate(14deg);
        position: absolute;
        bottom: -48px;
        right: 72px;

        .youtube {
            margin: 0 8px;

            img {
                width: 36px;
            }
        }

        .instagram {
            margin: 0 8px;

            img {
                width: 36px;
            }
        }

        .twitch {
            img {
                width: 48px;
            }
        }
    }
}


@media screen and (max-width: 960px) {
    .coming-soon-img {
        width: 326px;

        .social-links {
            bottom: 0;

            .youtube {
                margin: 0 8px;

                img {
                    width: 24px;
                }
            }

            .instagram {
                margin: 0 8px;

                img {
                    width: 24px;
                }
            }

            .twitch {
                img {
                    width: 36px;
                }
            }
        }
    }



}