.maintenance-wrapper {
    background-image: url("../../public/img/background1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .maintenance-img {
        background-image: url("../../public/img/manutenzione_text.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 512px;
        height: 512px;
        position: relative;

        .social-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 96px;
            right: 156px;
            transform: rotate(-2deg);

            .youtube {
                margin: 0 8px;

                img {
                    width: 36px;
                }
            }

            .instagram {
                margin: 0 8px;

                img {
                    width: 36px;
                }
            }

            .twitch {
                img {
                    width: 48px;
                }
            }
        }
    }
}


@media screen and (max-width: 960px) {
    .maintenance-wrapper {
        .maintenance-img {
            width: 360px;

            .social-links {
                bottom: 142px;
                right: 108px;
    
                .youtube {
                    margin: 0 8px;
    
                    img {
                        width: 24px;
                    }
                }
    
                .instagram {
                    margin: 0 8px;
    
                    img {
                        width: 24px;
                    }
                }
    
                .twitch {
                    img {
                        width: 36px;
                    }
                }
            }
        }
    }


}