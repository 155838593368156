.leaderboard-wrapper{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .leaderboard-title{
        width: 326px;
        margin: 24px 0;
    }

    .title{
        color: var(--logo-color);
    }

    .search-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border: 4px solid var(--logo-color);
        border-radius:  12px 12px 0 0;
        padding: 0 12px;
        width: 100%;

        .search-input{
            width: 100%;
            padding: 4px 0;
            font-size: 16px;
            border: 0;
            outline: none;
        }

        .search-icon{
            color: var(--logo-color);
            padding-left: 12px
        }
    }
}

#leaderboard{
    list-style: none;
    width: 512px;
    min-height: 256px;
    max-height: 256px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    background-color: #fff;

    

    .leaderboard-el{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #fff;
        padding: 8px 12px;

        p{
            margin: 0;
        }

        span{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            p{
                margin: 0 4px;
            }
        }
    }
}


@media screen and (max-width: 960px){
    .leaderboard-wrapper{
        width: 100%;
        padding: 0 24px;

        #leaderboard{
            width: 100%;
        }
    }
    
}