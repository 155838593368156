.shop-tag{
    background-color: beige;
    padding: 24px 48px;
    text-transform: uppercase;
    border-right: 1px solid #000;
}

.shop-tag:hover{
    background-color: goldenrod;
    cursor: pointer;
}

.shop-tag-active{
    background-color: goldenrod;
}
