#main-menu {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 96px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
}

#main-menu>a {
    color: var(--primary);
    background-color: var(--logo-color);
    padding: 12px 24px;
    border-radius: 24px;
    text-align: left;
    margin: 2px 0;
    text-decoration: none;
    border: 1px solid transparent;
    width: 172px;
}

#main-menu>a.nav-active {
    background-color: goldenrod;
}

#main-menu>a:hover {
    border: 1px solid var(--primary);
}

.main-menu {
    left: -200px;
    transition: left 1s ease-in-out;

    .social-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 12px 24px;

        a {
            background-color: none;
            padding: 0;
        }

        .youtube>img {
            width: 48px;
            padding: 4px;
        }

        .instagram>img {
            width: 48px;
            padding: 4px;
        }

        .twitch>img {
            width: 48px;
        }
    }
}

.main-menu-open {
    left: 0;
    transition: left 0.5s ease-in-out;

    .social-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 12px 24px;

        .youtube>img {
            width: 48px;
            padding: 4px;
        }

        .youtube>img:hover {
            filter: saturate(0.5);
        }

        .instagram>img {
            width: 48px;
            padding: 4px;
        }

        .instagram>img:hover {
            filter: saturate(0.5);
        }

        .twitch>img {
            width: 48px;
        }

        .twitch>img:hover {
            filter: saturate(0.5);
        }
    }
}

.btn-menu {
    padding: 12px 24px;
    position: relative;
    //background-color: rgba($color: #4B74AB, $alpha: 0);
    border-radius: 12px;
    color: #fff;
    border: 1px solid rgba($color: #000000, $alpha: 0);
    text-decoration: none;
    margin: 0 4px;
    transition: background-color 1s ease-in;
    cursor: pointer;

    .btn-menu-icon {
        margin-left: 24px;
    }
}

.btn-menu:hover {
    color: var(--primary);
    border: 1px solid #4B74AB;
    background-color: #fedea9;
    //background-color: rgba($color: #4B74AB, $alpha: 0.8);
}

.btn-menu.nav-active {
    color: var(--primary);
    border: 1px solid #4B74AB;
    background-color: #fedea9;
    //background-color: rgba($color: #4B74AB, $alpha: 0.8);
}

.sub-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn-sub-menu {
        position: relative;
        left: 24px;
        color: var(--primary);
        background-color: var(--logo-color);
        padding: 12px 24px;
        border-radius: 24px;
        width: 196px;
        text-align: left;
        margin: 2px 0;
        text-decoration: none;
        border: 1px solid transparent;
    }

    .btn-sub-menu:hover {
        border: 1px solid var(--primary);
    }

    .btn-sub-menu.nav-active{
        background-color: goldenrod;
    }
}



@media screen and (max-width: 960px) {
    .menu-btn {
        width: 32px;
        height: 32px;
    }
}