#profile-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    #profile-bonus {
        color: #fff;
        text-align: center;
    }

    .profile-data {
        background-color: var(--secondary);
        padding: 12px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 480px;
        border-radius: 24px;
        margin: 0 24px;

        #profile-title {
            width: 256px;
            margin: 24px 0;
        }

        .profile-image {
            width: 128px;
            height: 128px;
            border-radius: 50%;
            background-position: center center;
            background-size: contain;
        }

        .profile-info {
            list-style: none;
            padding: 0;
            width: 75%;

            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 4px 0;

                p:first-child {
                    font-weight: bold;
                    margin-right: 6px;
                }

                p {
                    margin: 0;
                }
            }
        }

        .logout-btn {
            background-color: var(--logo-color);
            border-radius: 24px;
            padding: 12px 24px;
        }

        .logout-btn:hover {
            background-color: goldenrod;
            cursor: pointer;
        }
    }

    .daily-scratchcard {
        background-color: var(--secondary);
        padding: 12px 24px;
        border-radius: 24px;
    }

}

@media screen and (max-width: 960px) {

    #profile-container {

        flex-direction: column;

        .profile-data {
            min-width: 90%;
            margin-bottom: 48px;

            .profile-info {
                width: 100%;
            }
        }
    }

}