.live-popup {
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 24px;
    color: red;
    position: fixed;
    bottom: 24px;
    right: 24px;
    text-decoration: none;

    p {
        font-size: 24px;
        margin: 0;
    }

    z-index: 10;
    box-shadow: 0px 0px 20px 0px #000;
    border: 2px solid red;
}

.live-popup:hover {
    color: #fff;
    background-color: red;
}