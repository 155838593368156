.shop-container {
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
}

#shop-legend {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    right: 48px;
    background-color: #fff;
    border-radius: 12px 0 0 12px;

    #stock-legend {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;

        #stock-icon {
            width: 24px;
            height: 24px;
            background-color: #ca1895;
        }

        #stock-info{
            margin: 0 12px;
            font-size: 12px;
        }
    }

    #stock-user-legend{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        
        #stock-user-icon {
            width: 24px;
            height: 24px;
            background-color: #c1dcff;
        }

        #stock-user-info{
            margin: 0 12px;
            font-size: 12px;
        }

    }
}

.shop-tags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.shop-tag:first-child:not(:only-child) {
    border-radius: 12px 0 0 12px;
}

.shop-tag:last-of-type:not(:only-child) {
    border-radius: 0 12px 12px 0;
}

.shop-tag:only-child {
    border-radius: 12px;
}

.reset-tags {
    margin: 0 8px;
    cursor: pointer;
}

.shop-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(316px, 1fr));
    grid-auto-rows: auto;
    column-gap: 12px;
    row-gap: 12px;
    padding: 0 24px;
    margin-top: 24px;
}



.shop-empty {
    color: #fff;
}

.empty-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



@media screen and (max-width: 960px) {

    .shop-container{
        display: flex;
        flex-direction: column;
    }

    .shop-empty {
        font-size: 18px;
    }

    .shop-cards-grid {
        display: grid;
        margin: 12px 0;
        grid-template-columns: auto;
        grid-auto-rows: auto;
    }

    #shop-legend{
        position: relative;
        flex-direction: row;
        left: 0;
        margin: 24px 0;
        width: max-content;
        align-self: center;

        #stock-legend{
            flex-direction: row;
        }

        #stock-user-legend{
            flex-direction: row;
        }
    }

    .shop-tags {
        flex-direction: column;
    }

    .shop-tag {
        width: 256px;
        text-align: center;
        border-bottom: 1px solid black;
    }

    .shop-tag:first-child:not(:only-child) {
        border-radius: 12px 12px 0 0;
    }
    
    .shop-tag:last-of-type:not(:only-child) {
        border-radius: 0 0 12px 12px ;
    }

    .reset-tags {
        margin: 18px 0;
    }

}