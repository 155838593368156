#not-found{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255,255,255,0.8);
    padding: 24px 48px;
    border-radius: 12px;

    #not-found-title{
        color: rgb(128, 0, 0);
        font-size: 36px;
        font-weight: bold;
        padding: 24px 0 0 0;
        margin: 0;
    }
}

@media screen and (max-width: 960px){
    #not-found{
        width: 100%;
        padding: 48px 12px;
        
        #not-found-title{
            font-size: 28px;
        }
    }
}