.no-game{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.pokemon-cards{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 24px;
    width: max-content;
    height: max-content;
    overflow-x: hidden;
    padding: 24px 0;
}

@media screen  and (max-width: 960px){
    .pokemon-cards{
        display: grid;
        grid-template-columns: 360px;
        grid-template-rows: auto;
        column-gap: 10px;
        row-gap: 24px;
        width: max-content;
        height: max-content;
        overflow-x: hidden;
    }

    .no-game{
        width: 100%;
        padding: 0 12px;
        flex-direction: row;
    }
}