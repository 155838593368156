@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

/*
.pokemon-card-container {
    min-height: 100vh;
    width: 100%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}
*/
.pokemon-card {
    //width: 500px;
    //height: 300px;
    font-family: 'Josefin Sans';
    width: 360px;
    height: 216px;
    color: #fff;
    cursor: pointer;
    perspective: 1000px;
}

.pokemon-card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;

    .front,
    .back {
        .map-img {
            width: 100%;
            position: absolute;
            top: -8px;
            left: 0;
            opacity: 0.2;
            z-index: -1;
            filter: grayscale(1);
        }
    }

    .front,
    .back {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
        position: absolute;
        top: -8px;
        left: 0;
        padding: 12px 14px;
        border-radius: 15px;
        overflow: hidden;
        z-index: 1;
        backface-visibility: hidden;
    }

    .pokeball-badge {
        width: 36px;
    }

    .card-data {
        font-size: 35px;
        margin: 0px;
        padding: 6px 0;
        border-radius: 12px;
        background-color: rgba($color: #000, $alpha: 0.3);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start !important;

        .pokemon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 33%;

            .pokemon-image {
                width: 72px;
                height: 72px;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .pokemon-name {
                margin: 0;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card-number {
            margin: 2px 0;
        }

        .column {
            .card-date {
                margin: 0;
            }
        }
    }



    .card-info {
        margin-top: 0px;
        align-items: flex-end;

        .row {
            align-items: flex-end;
        }

        .card-label {
            font-size: 8px;
            text-transform: uppercase;
            margin: 4px 0;
        }

        .card-trainer {
            margin-left: 12px;

            .card-trainer-name {
                font-size: 16px;
                margin: 0;
            }
        }

    }

    .card-holder {
        font-size: 12px;
        margin-top: 48px;
        text-transform: uppercase;
    }



    .back {
        transform: rotateY(180deg);

        .map-img{
            transform: scaleX(-1);
        }

        .bar {
            background: #222;
            margin-left: -30px;
            margin-right: -30px;
            height: 60px;
            margin-top: 10px;
        }

        .card-summary {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;

            .card-summary-row {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
                width: 100%;
                margin-top: 4px;

                .pokemon-name {
                    text-transform: uppercase;
                    margin: 0;
                    margin-top: 2px;
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 400;
                }
                .pokemon-types{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: end;
                }
            }
        }

        .card-cvv {
            margin-top: 20px;
        }

        .card-cvv div {
            flex: 1;
        }

        .card-cvv img {
            width: 100%;
            display: block;
            line-height: 0;
        }

        .card-cvv p {
            background: #fff;
            color: #000;
            font-size: 22px;
            padding: 10px 20px;
        }

        .card-text {
            margin-top: 30px;
            font-size: 14px;
        }

        .signature {
            font-size: 12px;
            margin: 0;
            margin-top: 24px;
        }
    }


}

.pokemon-card:hover .pokemon-card-inner {
    transform: rotateY(-180deg);
}