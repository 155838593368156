.shop-card {
    position: relative;
    background-color: rgba($color: #4B74AB, $alpha: 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Josefin Sans';
    border-radius: 24px;
    box-shadow: 0px 0px 10px 10px #032c5157;
    //margin: 12px;

    transition: box-shadow 1s ease-in;

    .stock-size{
        position: absolute;
        left: 12px;
        top: 12px;
        margin: 0;
        color: #ca1895;
        font-weight: bold;
    }

    .infinity-icon-stock{
        position: absolute;
        left: 12px;
        top: 8px;
        filter: invert(32%) sepia(98%) saturate(7443%) hue-rotate(302deg) brightness(90%);
    }

    .redeems-count{
        position: absolute;
        left: 12px;
        top: 28px;
        margin: 0;
        color: #c1dcff;
        font-weight: bold;
    }

    .infinity-icon-redeems {
        position: absolute;
        top: 24px;
        left: 12px;
        filter: invert(100%) sepia(100%) saturate(200%) hue-rotate(180deg) brightness(1.1);
    }

    .admin-freeze-btn{
        background-color: var(--ice);
        color: white;
        padding: 12px 6px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 24px 0 24px;
        font-size: 14px;
    }

    .admin-freeze-btn:hover{
        cursor: pointer;
        background-color: #006eff;
    }

    .shop-card-image {
        background-size: cover;
        background-position: center center;
        height: 256px;
        border-radius: 24px 24px 0 0;
    }

    .shop-card-metadata {
        padding: 12px 24px;

        .title {
            color: var(--primary);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 24px 0;
            text-transform: uppercase;

            h3 {
                margin: 2px 0;
                text-align: center;
            }

            .only-for-sub {
                color: goldenrod;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                p {
                    margin: 0;
                    text-align: center;
                    margin-left: 4px;
                    margin-top: 2px;
                }
            }

            .item-freezed{
                color: var(--ice);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                p {
                    margin: 0;
                    text-align: center;
                    margin-left: 4px;
                    margin-top: 2px;
                }
            }
        }

        .description {
            margin: 0;
            text-align: center;
            color: #bbbbbb;
            padding: 12px 24px;
            border-radius: 12px;
            background-color: rgba($color: #1b2b36, $alpha: 0.5);
        }
    }

    .metadata-fullwidth {
        width: 100%;
    }

    .shop-card-info {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;

        .price {
            color: #fff;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                margin-left: 8px;
            }
        }

        .buy-btn {
            background-color: #8d0063;
            border-radius: 24px;
            margin-top: 12px;
            color: #fff;
            padding: 8px 16px;
            cursor: pointer;
        }

        .buy-btn:hover {
            background-color: #fff;
            color: var(--primary);
        }

        .not-available-btn {
            background-color: darkgrey;
            border-radius: 24px;
            color: #fff;
            padding: 8px 16px;
        }
    }

    .description {
        color: var(--primary);
    }
}

.shop-card:hover {
    box-shadow: 0px 0px 10px 10px rgba($color: #fff, $alpha: 0.5);
}

.freezed-card:hover{
    box-shadow: 0px 0px 10px 10px rgba($color: #006eff, $alpha: 0.5);
    cursor: not-allowed;
}

@media screen and (max-width: 960px) {
    .shop-card {
        padding: 12px 24px;

        .shop-card-image {
            height: 128px;
        }

        .shop-card-metadata {
            padding: 0;
            
            .shop-card-info {
                flex-direction: row;
                align-items: center;
                padding: 24px 12px;

                .buy-btn {
                    margin-top: 0;
                }
            }
        }

        .shop-card-info {
            .not-available-btn {
                background-color: darkgrey;
                border-radius: 24px;
                color: #fff;
                padding: 8px 16px;
                font-size: 12px;
                max-width: 50%;
                text-align: center;
            }
        }
    }
}