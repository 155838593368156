.profile-tooltip {
    color: var(--primary);
    position: absolute;
    top: 128px;
    //background-color: rgba(72, 116, 171, 0);
    background-color: rgba(254, 222, 169, 0);
    right: 24px;
    padding: 12px 24px;
    border-radius: 24px;
    //transition: background-color 2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //opacity: 0;
    visibility: hidden;
}

.profile-tooltip-open {
    background-color: rgba(254, 222, 169, 1);
    //background-color: rgba(72, 116, 171, 1);
    //opacity: 1;
    visibility: visible;
}

.profile-tooltip-chevron {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;

    //border-bottom: 24px solid rgba(72, 116, 171, 0);
    border-bottom: 24px solid rgba(254, 222, 169, 0);
    //transition: border-bottom 2s ease-in-out;
    visibility: hidden;
    top: -24px;
    right: 28px;
}

.profile-tooltip-chevron-open {
    border-bottom: 24px solid rgba(254, 222, 169, 1);
    //border-bottom: 24px solid rgba(72, 116, 171, 1);
    visibility: visible;
}

.profile-tooltip-title {
    margin: 0;
}

.profile-tooltip-subscriber-subtitle {
    margin: 0;
    color: var(--primary);
}

.profile-tooltip-balance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        font-size: 28px;
        margin: 0;
        margin-right: 12px;
    }


}

.profile-tooltip-data {
    color: var(--primary);
    list-style: square;
}

.profile-tooltip-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
}

.profile-tooltip-buttons>a {
    color: var(--primary);
    text-decoration: none;
    cursor: pointer;
}

.profile-tooltip-buttons>a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 960px) {

    .profile-tooltip {
        right: 8px;
        top: 108px;
    }

    .profile-tooltip-chevron {
        right: 12px;
        top: -18px;
    }

    .profile-tooltip-open {
        background-color: rgba(254, 222, 169, 1);

        visibility: visible;
    }

    .profile-tooltip-chevron-open {
        //border-bottom: 24px solid rgba(72, 116, 171, 1);
        border-bottom: 24px solid rgba(254, 222, 169, 1);
        visibility: visible;
    }

}