#schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 512px;
    color: #fff;

    img {
        width: 256px;
        margin: 24px 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        .schedule-day {
            padding: 12px 24px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid #fedea9;
            background-color: #fedda92c;

            .schedule-day-title {
                font-weight: bold;
                color: goldenrod;
            }

            .no-stream-day {
                color: #c0c0c0;
            }

            p {
                width: 33%;
                text-align: center;
            }
        }
    }
}

.latest-clips-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .other-clips{
        color: goldenrod;
    }
}

#latest-announcements-title {
    width: 196px;
    margin: 24px 0;
}


.latest-announcements {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        background-color: #fff;
        padding: 12px 24px;
        margin-bottom: 12px;
        border-radius: 24px;
        width: 512px;

        .announcement-header {

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;


            h3 {
                color: var(--primary);
            }

            p {
                color: var(--secondary);
            }
        }


    }

    li:last-child {
        margin-bottom: 0;
    }
}


#latest-clips-title {
    width: 172px;
    margin: 24px 0;
}

.right-slide-btn {
    cursor: pointer;
    width: 52px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: font-size 0.1s ease-in;
}

.right-slide-btn:hover {
    font-size: 36px;
}

.left-slide-btn {
    cursor: pointer;
    width: 52px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: font-size 0.1s ease-in;
}

.left-slide-btn:hover {
    font-size: 36px;
}

@media screen and (max-width: 960px) {

    #schedule {
        width: 100%;
        height: auto;
        max-height: 100%;

        .schedule-day {
            p {
                font-size: 12px;
            }
        }
    }

    .last-clips-wrapper {
        width: 100%;
    }

    .latest-announcements {
        width: 100%;

        li {
            border-radius: 0;
            width: 100%;
        }
    }

}