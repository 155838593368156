@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
    --primary: #03142E;
    --secondary: #4B74AB;
    --twitch-primary: #613FA0;
    --twitch-secondary: #A970FF;
    --logo-color: #fedea9;
    --bg-color: #1B2B36;
    --ice: #c1dcff;
}

html{
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: "Montserrat", 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root{
    height: 100%;
}

.app-wrapper {
    //background-color: var(--primary);
    background-image: url("../../public/img/background.png");
    background-position: center center;
    //background-repeat: no-repeat;
    background-repeat: repeat-y;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    
}

.content-wrapper{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 128px;
    overflow-y: auto;
}


@media screen and (max-width: 960px){
    .content-wrapper{
        flex: 1;
        justify-content: flex-start;
        margin-top: 148px;
    }

}