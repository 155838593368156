.footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    padding: 12px 48px;
    justify-content: space-between;
    box-sizing: border-box;
    height: 48px;
    bottom: 0;
    position: relative;
    color: var(--logo-color);
}