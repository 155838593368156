.pokemon-scores-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba($color: #5c5c5c, $alpha: 0.8);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #close-btn {
        font-size: 32px;
        position: fixed;
        right: 48px;
        top: 48px;
        cursor: pointer;
    }

    #scores-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100vh;
        overflow-y: scroll;
        background-color: rgba($color: #fff, $alpha: 1.0);
        width: 50%;

        #scores-title {
            font-size: 24px;
            text-align: center;

            p {
                font-weight: bold;
                display: inline-block;
            }
        }

        .score-element {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 12px 24px;
            width: 100%;

            .score-element-info {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: 96px;
                    height: 96px;
                }

                p {
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {

    .pokemon-scores-overlay {

        #close-btn {
            font-size: 24px;
            position: fixed;
            right: 24px;
            top: 24px;
            cursor: pointer;
        }

        #scores-title{
            font-size: 24px;

            p{
                margin: 0;
            }
        }

        #scores-list {
            width: 100%;

            .score-element{
                .score-element-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                display: flex;
                flex-direction: column;
                border: 1px solid black;
                width: 90%;
            }
        }
    }

}