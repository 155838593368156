.sc__inner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 }
 .sc__wrapper {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
 }
 
 .sc__container {
    position: relative;
    overflow: hidden;
    max-width: 300px;
 }
 
 .sc__container > img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
 }
 
 .sc__container canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
 }
 
 .sc__infos {
    text-align: center;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
 }
 