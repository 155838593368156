.pokemon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pokemon-admin-tab{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #fff;

    .pokemon-admin-control{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 12px;
        padding: 12px 48px;
        border: 2px solid goldenrod;
    }
}

.pokedex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 75%;
    padding: 24px 48px;

    .pokedex-title {
        color: goldenrod;
    }

    .pokedex-searchbar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        input {
            height: 48px;
            font-size: 18px;
            width: inherit;
        }

        a {
            padding: 14px 24px;
            color: #fff;
            background-color: var(--secondary);
        }

        a:hover {
            cursor: pointer;
            color: goldenrod;
        }
    }

    .pokedex-list-loading {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 480px;
        background-color: #dadada;

    }

    .pokedex-list {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 360px;
        overflow-y: scroll;
        background-color: #dadada;


        li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #c2c2c2;
            padding: 12px 24px;
            width: 100%;

            :first-child {
                width: 40%;
            }

            .pokemon-icon {
                width: 48px;
                height: 48px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                cursor: grab;
            }

            .pokemon-type {
                width: 30%;

                img {
                    width: 48px;
                }
            }

            p {
                width: 25%;
            }

            .pokedex-item-buttons {
                width: 5%;
            }

            .pokemon-title {
                text-transform: uppercase;
            }
        }

        li:hover {
            background-color: gray;
        }
    }
}

.user-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 48px;

    .pokemon-team-title {
        color: goldenrod;
    }

    .pokemon-team {
        display: grid;
        margin: 24px 0;
        grid-template-columns: auto auto auto;

        .pokemon-slot {
            background-color: gray;
            border: 1px solid #c2c2c2;
            width: 96px;
            height: 96px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 24px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .pokemon-slot-dragged {
            cursor: grab;
            background-color: rgba(0, 255, 0, 0.5);
        }

        .pokemon-slot:hover {
            cursor: pointer;
            background-color: rgba(255, 0, 0, 0.5);
        }
    }
}

.dragged-pokemon {
    cursor: inherit !important;
}

@media screen and (max-width: 960px) {

    .pokemon-admin-tab{
        flex-direction: column;
    }

    .pokemon {
        flex-direction: column-reverse;

        .user-team {
            padding: 12px 48px;

            .pokemon-team {
                margin: 0 0 24px 0;
            }
        }

        .pokedex {
            width: 100%;
            padding: 0;

            .pokedex-list {
                li {
                    position: relative;
                    padding: 12px 12px 24px 12px;
                    border-bottom: 1px solid var(--secondary);

                    .pokemon-title {
                        font-size: 14px;
                    }

                    .pokemon-type {
                        width: 10%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        position: absolute;
                        bottom: 6px;
                        left: 18px;

                        img {
                            width: 48px;
                        }
                    }

                    .pokemon-points {
                        width: 15%;
                        font-size: 12px;
                        text-align: center;
                        position: absolute;
                        right: 12px;
                        bottom: 0;
                    }

                    .pokedex-item-buttons {
                        width: 10%;
                    }
                }
            }


        }
    }
}