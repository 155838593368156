.purchase-history-title {
    color: #fff;
    text-transform: uppercase;
}

.purchase-searchbar {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;

    input {
        height: 48px;
        font-size: 18px;
        width: 100%;
    }

    a {
        padding: 14px 24px;
        color: #fff;
        background-color: var(--secondary);
    }

    a:hover {
        cursor: pointer;
        color: goldenrod;
    }
}

.purchased-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 80%;
    height: 440px;
    overflow-y: scroll;
}

.purchase-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 48px;
    background-color: rgba($color: #afafaf, $alpha: 0.8);
    border-radius: 24px;
    margin-bottom: 8px;


    li span{
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 22%;
        color: var(--primary);
    }

    li>p {
        margin: 0;
    }
}

.purchase-item:hover {
    background-color: rgba($color: #fff, $alpha: 1);
}


@media screen and (max-width: 960px) {
    .purchase-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 24px 12px;
        

        li {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            margin-bottom: 24px;
        }

        li:last-child{
            margin-bottom: 0;
        }
    }

    .purchase-item > span {
        text-align: center;
        width: 100%;
        display: block;
    }

    .purchase-item:hover {
        background-color: rgba($color: #fff, $alpha: 0.5);
    }
}