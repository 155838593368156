.fantapoke-leaderboard-wrapper {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 75%;
    padding: 0 48px;

    .fantapoke-leaderboard-title {
        width: 326px;
        margin: 24px 0;
    }

    .fantapoke-title {
        color: var(--logo-color);
    }

    .fantapoke-search-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border: 4px solid var(--logo-color);
        border-radius: 12px 12px 0 0;
        padding: 0 12px;
        width: 100%;

        .search-input {
            width: 100%;
            padding: 4px 0;
            font-size: 16px;
            border: 0;
            outline: none;
        }

        .search-icon {
            color: var(--logo-color);
            padding-left: 12px
        }
    }
}

#fantapoke-leaderboard {
    list-style: none;
    width: 100%;
    height: 416px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    background-color: #fff;

    .fantapoke-leaderboard-scores{
        color: var(--secondary);
        cursor: pointer;
    }

    .fantapoke-leaderboard-scores:hover{
        color: var(--primary);
    }

    .fantapoke-leaderboard-el {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #fff;
        padding: 8px 12px;

        > *{
            width: 33%;
        }

        p {
            margin: 0;
            text-align: center;
        }

        p:first-child{
            text-align: left;
        }

        p:last-child{
            text-align: right;
        }

        div > p{
            text-align: right;
        }
    }
}


@media screen and (max-width: 960px) {
    .fantapoke-leaderboard-wrapper {
        width: 100%;
        padding: 0 24px;

        #fantapoke-leaderboard {
            width: 100%;

            .fantapoke-leaderboard-el{
                margin-bottom: 32px;
                position: relative;

                > *{
                    width: 50%;
                }

                .fantapoke-leaderboard-scores{
                    position: absolute;
                    left: 12px;
                    bottom: -12px;
                }
            }
        }

        .pokemon-scores-overlay{
            #scores-list{
                #scores-title{
                    padding: 24px 0;
                }
            }
        }
    }

}