
.pokemon-insert{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label{
        margin-top: 12px;
    }
}

.pokemon-list-admin{
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.pokemon-list{
    background-color: #ededed;
    height: auto;
    max-height: 240px;
    overflow-y: scroll;

    .pokemon-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #bbbbbb;
        padding: 0px 24px;        

        .pokemon-name{
            width: 20%;
            text-align: left;
        }

        .pokemon-points{
            width: 20%;
        }

        .pokemon-type{
            width: 20%;
            text-align: left;
        }

        .pokemon-commands{
            text-align: left;
            width: 20%;
        }
    }

}

.pokemon-user-admin{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
}

.pokemon-user-list{
    background-color: #ededed;
    height: auto;
    max-height: 240px;
    overflow-y: scroll;

    .user-pokemon{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #bbbbbb;
        padding: 0 48px;

        .pokemon-user{
            width: 20%;
        }

        .pokemon-name{
            width: 20%;
        }

        .pokemon-commands{
            width: 20%;
        }

    }
}

.header-bar{
    position: absolute;
    width: 100%;
    background-color: aquamarine !important;
}


.pokemon-user-form{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pokemon-user-search-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .radio-group{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}