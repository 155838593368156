.pokemon-auction-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
}

.loading{
    margin: 24px;
}

.pokemon-info {
    border: 1px solid white;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pokemon-info-image{
        width: 128px;
        height: 128px;
        background-size: cover;
    }

    p {
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
}

.in-game{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.search-pokemon{
    label {
        width: 128px;
        display: inline-block;
    }

    .search-btn{
        padding: 1px 8px;
        background-color: #fff;
        //border-radius: 12px;
        color: #000;
    }

    .search-btn:hover{
        background-color: #696969;
        color: #000;
        cursor: pointer;
    }

    .search-error{
        color: red;
    }

    .search-success{
        color: lime;
    }

    .pokemon-types{
        margin-top: 24px;
        width: 480px;
    }

    .auction-start-btn{
        margin-top: 24px;
    }
}