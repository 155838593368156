.pokemon-scores-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 24px;
    color: #fff;

    .pokemon-buttons {
        margin-top: 48px;
    }

    .pokemon-scores-form {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid white;
        border-radius: 24px;
        width: 100%;
        margin: 4px 0;

        .pokemon-scores-form-remove-btn{
            position: absolute;
            right: 24px;
            top: 24px;
            color: red;
            cursor: pointer;
        }

        .pokemon-choose {
            position: relative;
            padding: 0 48px;

            input {
                width: 128px;
            }

            .pokemon-choose-list {
                position: absolute;
                max-height: 256px;
                overflow-y: scroll;
                z-index: 10;

                .pokemon-data {
                    display: flex;
                    flex-direction: column;
                    background-color: lightcyan;
                    color: #000;
                    padding: 12px 24px;
                    width: 256px;

                    p {
                        margin: 0;
                    }

                    .pokemon-data-types {
                        display: flex;
                        flex-direction: row;
                    }
                }

                .pokemon-data:hover {
                    background-color: aqua;
                    cursor: pointer;
                }
            }
        }

        .pokemon-image {
            width: 128px;
            height: 128px;
            border: 1px solid #fff;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .pokemon-choose-body {
            display: flex;
            flex-direction: column;

            .pokemon-choose-info{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;
                font-weight: bold;
            }

            .pokemon-scores {
                list-style: none;
                display: flex;
                flex-direction: row;
                padding: 0;

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin: 0 12px;
                }

                li:first-child{
                    margin-left: 0;
                }

                li:last-child{
                    margin-right: 0;
                }

                .multiplier {
                    p {
                        font-weight: lighter;
                        color: aquamarine;
                    }

                    margin-left: 48px;
                }
            }
        }

    }
}

.results-list {
    color: #fff;

    .pokemon-result {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 12px 24px;
        border-bottom: 1px solid #fff;

        .not-in-team{
            color: red;
        }
    }

    .results-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
    }
}