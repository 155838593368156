hr {
    border: 1px solid red;
    width: 100%;
}

.loading-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    .redeems-table {
        position: relative;
        color: #000;
        background-color: #fff;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            height: 48px;
        }

        li.redeems-search-bar{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding: 0;

            input{
                font-size: 18px;
                width: 100%;
                height: 48px;
            }

            .redeems-search-abort{
                padding: 12px 24px;
                height: 48px; 
                background-color: var(--secondary);
                color: #fff;
                cursor: pointer;
            }
            .redeems-search-abort:hover{
                color: var(--secondary);
                background-color: #fff;
            }
        }

        li.redeems-list-header {
            position: absolute;
            background-color: aqua;
            left: 0;
            top: 48px;
            width: 100%;

            p {
                font-weight: bold;
                width: 20%;
            }
            
        }

        

        .redeems-items {
            max-height: 256px;
            height: 256px;
            overflow-y: scroll;
            margin-top: 48px;
        }

        li.redeems-item {
            text-align: left;

            p {
                width: 20%;
            }
        }

    }

    .admin-section-title {
        color: #fff;
        text-align: center;
    }

    .admin-section-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0 48px;

        #schedule-admin {
            color: #fff;
            padding: 0;
            list-style-type: none;
            padding: 0;
        }

        #schedule-form {
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                margin-top: 24px;
            }

            .schedule-day-form {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 480px;

                label {
                    width: 25%;
                    text-align: center;
                }

                input {
                    width: 25%;
                }
            }
        }

        .admin-section-form {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            color: #fff;

            input {
                width: 75%;
            }

            button {
                margin-top: 12px;
                text-transform: uppercase;
            }
        }

        .admin-section-list {
            margin: 0;
            list-style-type: none;
            max-width: 50%;
            padding: 0;
            max-height: 560px;
            overflow-y: scroll;

            li {
                background-color: #fff;
                padding: 48px 24px;
                margin-bottom: 12px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                position: relative;

                p {
                    margin: 0;
                }

                .item-buttons {
                    position: absolute;
                    top: 24px;
                    right: 24px;

                    svg {
                        margin-left: 8px;
                        font-size: 24px;
                        cursor: pointer;
                    }

                    svg:first-child {
                        font-size: 18px;
                    }
                }

                form {
                    label {
                        margin-right: 8px;
                        margin-top: 12px;
                        display: block;
                    }

                    textarea {
                        height: auto;
                        width: 75%;
                    }

                    input {
                        width: 75%;
                    }

                    button {
                        margin-top: 12px;
                    }
                }
            }
        }


        li:last-child {
            margin-bottom: 0;
        }

    }
}


.announcements-list {
    li {
        p {
            max-width: 75%;
        }
    }
}

@media screen and (max-width: 960px) {
    .admin-section {
        .admin-section-controls {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 12px;

            #schedule-img {
                width: 100%;
            }

            #schedule-form {
                width: 100%;

                .schedule-day-form {
                    width: 80%;
                }
            }

            .admin-section-form {
                width: 90%;
            }

            .admin-section-list {
                max-width: 100%;
            }
        }
    }
}